<template>
  <v-dialog
    v-model="changeCaseModelCategoryDialog"
    persistent
    keydown
    max-width="600px"
    transition="dialog-bottom-transition"
  >
    <v-card
      class="pa-2"
    >
      <v-card-title>
        {{ $t('actions|change_category') }}
      </v-card-title>
      <v-icon
        style="position: absolute; top: 10px; right: 10px;"
        @click="changeCaseModelCategoryDialog = false"
      >
        mdi-close
      </v-icon>
      <div class="text-center">
        <v-progress-circular
          v-show="isLoading"
          color="warning"
          width="4"
          indeterminate
          size="40"
        />
      </div>
      <v-select
        v-model="selectedCategory"
        outlined
        dense
        :label="translateCategoryIfNeeded(defaultCategory)"
        :items="availableCategories"
        item-text="value"
        item-value="value"
        class="mx-6 mt-2"
      >
        <template
          slot="item"
          slot-scope="data"
        >
          <v-list
            v-model="selectedCategory"
          >
            <v-list-item
              three-line
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon
                    x-small
                  >
                    mdi-tag-outline
                  </v-icon>
                  {{ data.item.value }}
                </v-list-item-title>
                <v-list-item-subtitle>category created by:</v-list-item-subtitle>
                <v-list-item-subtitle>{{ data.item.createdBy }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-select>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          small
          rounded
          min-width="150"
          :loading="isLoading"
          :disabled="disabledRule"
          @click="changeCaseModelCategory(); changeCaseModelCategoryDialog = false"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    currentCaseForm: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      description: '',
      selectedCategory: '',
      defaultCategory: '',
      changeCaseModelCategoryDialog: false,
      isLoading: false,
      disabledRule: true
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    availableCategories () {
      let categories = []
      if (this.currentCaseForm.sharedWith.accounts.length) {
        this.currentCaseForm.sharedWith.accounts.forEach(acc => {
          acc.myCaseModelsCategories.forEach(cat => {
            const newCat = {
              createdBy: acc.accountName ? acc.accountName : this.$t('mixed|account_no_longer_available'),
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      if (this.currentCaseForm.sharedWith.company.length) {
        this.currentCaseForm.sharedWith.company.forEach(company => {
          company.companyCaseModelsCategories.forEach(cat => {
            const newCat = {
              createdBy: company.companyData.companyName,
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      if (this.currentCaseForm.sharedWith.groups.length) {
        this.currentCaseForm.sharedWith.groups.forEach(group => {
          group.groupCaseModelsCategories.forEach(cat => {
            const newCat = {
              createdBy: group.groupName,
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      return categories
    }
  },
  watch: {
    selectedCategory (val) {
      if (val) this.disabledRule = false
      else this.disabledRule = true
    },
    changeCaseModelCategoryDialog (val) {
      if (!val) this.resetDialog()
    }
  },
  created () {
    EventBus.$on('open-change-case-model-category-dialog', this.openDialog)
  },
  beforeDestroy () {
    EventBus.$off('open-change-case-model-category-dialog', this.openDialog)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateCaseForm: 'caseForms/updateCaseForm'
    }),
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|not_available')
      else return category
    },
    async changeCaseModelCategory () {
      this.loading = true
      const payload = {
        category: this.selectedCategory
      }
      try {
        await this.updateCaseForm({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    openDialog (item) {
      this.defaultCategory = Object.values(item).toString()
      this.changeCaseModelCategoryDialog = true
    },
    resetDialog () {
      this.description = ''
      this.selectedCategory = ''
      this.defaultCategory = ''
      this.isLoading = false
      this.disabledRule = true
    }
  }
}
</script>
