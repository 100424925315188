import { render, staticRenderFns } from "./ChangeCaseModelCategory.vue?vue&type=template&id=fd27cf06"
import script from "./ChangeCaseModelCategory.vue?vue&type=script&lang=js"
export * from "./ChangeCaseModelCategory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports