<template>
  <v-card
    height="100%"
    class="pa-3"
  >
    <v-card-title class="pa-2">
      <span
        v-if="!isCustomField"
        style="font-size: 17px"
      >
        {{ $t('documents|required_documents') }}
      </span>
      <v-icon
        style="position: absolute; right: 10px; top: 10px;"
        @click="$emit('close-dialog'); reset()"
      >
        mdi-close
      </v-icon>
      <v-slide-y-transition leave-absolute>
        <div
          v-if="isCustomField"
          class="headline d-flex align-center"
        >
          <v-btn
            icon
            large
            class="mr-2"
            @click="returnToList"
          >
            <v-icon v-text="icons.arrowLeft" />
          </v-btn>
          <span style="font-size: 15px">{{ $t('actions|add_custom_document_type') }}</span>
        </div>
      </v-slide-y-transition>
    </v-card-title>
    <v-text-field
      v-if="!isCustomField"
      ref="query"
      v-model="query"
      :solo-inverted="soloInverted"
      :solo="!soloInverted"
      rounded
      dense
      :placeholder="$t('actions|search_document_type')"
      autocomplete="off"
      hide-details
      prepend-inner-icon="mdi-magnify"
      class="pt-1 mx-4"
      @focus="soloInverted = false"
      @blur="soloInverted = true"
    />
    <v-card-text class="pa-0">
      <v-slide-y-transition leave-absolute>
        <div
          v-if="isCustomField"
          class="pa-4"
        >
          <v-text-field
            ref="newFieldName"
            v-model="newField.name"
            :label="$t('documents|document_type')"
            required
            :error-messages="newFieldNameErrors"
            class="mt-0"
            autocomplete="off"
            @input="$v.newField.name.$touch()"
            @blur="$v.newField.name.$touch()"
          />
          <v-btn
            style="position: absolute; right: 15px;"
            min-width="100"
            color="primary"
            :disabled="!newField.name"
            rounded
            small
            @click="selectCustomSuggestion"
          >
            {{ $t('actions|save') }}
          </v-btn>
        </div>
        <div v-else>
          <v-list-item
            class="suggestion-list__item px-2 mt-2"
            :class="`list-item-0`"
            :input-value="0 === selectedField"
            @click="openCustomSuggestion"
          >
            <v-list-item-content class="px-2">
              <v-list-item-title>
                {{ $t('common|custom') }}
                <v-icon
                  small
                >
                  {{ icons.cog }}
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-menu
            v-if="!isCustomField"
            bottom
            :offset-x="false"
            :offset-y="true"
          >
            <template v-slot:activator="{ on }">
              <span
                class="ml-4 black--text"
                style="cursor: pointer; font-size: 15px"
                v-on="on"
              >
                {{ $t('common|language') }}
                <v-icon
                  class="ml-n2"
                >
                  mdi-menu-down
                </v-icon>
              </span>
            </template>

            <v-list
              v-model="selectedLanguage"
              min-width="120"
            >
              <template v-for="lang in languages">
                <v-list-item
                  :key="lang"
                  @click="selectedLanguage = lang"
                >
                  <v-list-item-title>{{ lang }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <v-list
            ref="list"
            dense
            class="virtual-scroll-list mt-2"
          >
            <v-list-item
              v-for="(suggestion, index) in filteredFields"
              :key="suggestion.id"
              :class="{active: activeItems.indexOf(index)>-1}"
              @click="toggleActive(index, suggestion)"
            >
              <template>
                <v-list-item-content class="px-2">
                  <div class="d-flex justify-start align-center">
                    <v-icon
                      left
                      class="ml-1"
                      color="primary"
                    >
                      mdi-file-hidden
                    </v-icon>
                    {{ suggestion.name }}
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    :input-value="activeItems.indexOf(index)>-1"
                    color="primary"
                  />
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list>
        </div>
      </v-slide-y-transition>
    </v-card-text>
    <v-card-actions
      class="mt-3"
    >
      <v-spacer />
      <v-btn
        v-if="!isCustomField"
        class="primary"
        small
        rounded
        :disabled="!activeSuggestions.length"
        min-width="150"
        @click="addRequiredDocsToCaseModel"
      >
        {{ $t('actions|submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mdiCog, mdiArrowLeftThick } from '@mdi/js'
import { v4 as uuidv4 } from 'uuid'

export default {
  mixins: [validationMixin],
  props: {
    currentCaseForm: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    removeFieldInput: {
      type: Boolean,
      default: true
    },
    resetDocumentSuggestionsDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      query: null,
      selectedField: null,
      selectedLanguage: localStorage.getItem('preferedLanguage') || 'en',
      isCustomField: false,
      soloInverted: true,
      icons: {
        cog: mdiCog,
        arrowLeft: mdiArrowLeftThick
      },
      newField: {
        name: null
      },
      // newFieldTypes: [
      //   'text-input',
      //   'date-input',
      //   'currency-input'
      // ],
      hover: false,
      activeItems: [],
      activeSuggestions: []
    }
  },
  validations: {
    newField: {
      name: { required }
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings
    }),
    languages () {
      return this.settings.activeLanguages
    },
    fieldTypes () {
      if (this.removeFieldInput) return this.newFieldTypes
      else return [...this.newFieldTypes, 'file-input']
    },
    documentSuggestions () {
      const suggestions = Object.keys(this.settings.fields).reduce((acc, key) => {
        if (this.settings.fields[key].type === 'file-input') {
          acc[key] = this.settings.fields[key]
        }
        return acc
      }, {})
      return suggestions
    },
    _fields () {
      let _filtered
      const suggestions = Object.keys(this.documentSuggestions).map((key) => {
        let name = this.documentSuggestions[key].label[this.selectedLanguage] ?? undefined
        if (Array.isArray(this.documentSuggestions[key].label[this.selectedLanguage])) {
          name = this.documentSuggestions[key].label[this.selectedLanguage].join(' / ')
        }
        return {
          id: uuidv4(),
          name,
          fieldType: 'file-input',
          fieldKey: key,
          key: key,
          language: this.selectedLanguage
        }
      })
      // remove suggestions with no label in this actual language
      const removeUndefined = (obj) => {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
            return
          }
        }
        return obj
      }
      _filtered = suggestions.filter(removeUndefined)
      if (!_filtered.length) _filtered.push({ name: this.$t('common|no_results_found') })
      return _filtered
    },
    filteredFields () {
      if (!this.query || this.query === '') return this._fields
      return this._fields.filter((s) => {
        const name = s.name ? s.name.toLowerCase() : ''
        const query = this.query.toLowerCase()
        return name.includes(query)
      })
    },
    newFieldKey () {
      return this.newField.name && this.newField.name.split(' ').join('_').toLowerCase()
    },
    customFieldExists () {
      return Object.keys(this.documentSuggestions).some((key) => key.toLowerCase() === this.newFieldKey)
    },
    newFieldNameErrors () {
      const errors = []
      if (!this.$v.newField.name.$dirty) return errors
      if (this.customFieldExists) errors.push(this.$t('common|field_registered'))
      !this.$v.newField.name.required && errors.push(this.$t('common|field_required'))
      return errors
    },
    newFieldTypeErrors () {
      const errors = []
      if (!this.$v.newField.fieldType.$dirty) return errors
      !this.$v.newField.fieldType.required && errors.push(this.$t('common|field_required'))
      return errors
    }
  },
  watch: {
    resetDocumentSuggestionsDialog (value) {
      if (value) {
        this.reset()
      }
    },
    query () {
      this.selectedField = null
    },
    async value (isVisible) {
      if (isVisible) {
        this.isCustomField = false
        this.query = null
        this.selectedField = null
        await this.$nextTick()
        this.$refs.query.focus()
      }
    },
    async isCustomField (value) {
      if (!value) {
        this.query = null
        this.selectedField = null
        this.resetCustomFields()
        await this.$nextTick()
        this.$refs.query.focus()
      } else {
        await this.$nextTick()
        this.$refs.newFieldName.focus()
      }
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      addCaseRequiredDocuments: 'caseForms/addCaseRequiredDocuments'
    }),
    toggleActive (index, suggestion) {
      let pos = this.activeItems.indexOf(index)
      let posSuggestion = this.activeSuggestions.indexOf(index)
      pos === -1 ? this.activeItems.push(index) && this.activeSuggestions.push(suggestion) : this.activeItems.splice(pos, 1) && this.activeSuggestions.splice(posSuggestion, 1)
    },
    async addRequiredDocsToCaseModel () {
      if (!this.activeSuggestions.length) {
        this.addToast({
          title: this.$t('warning|no_current_selection'),
          color: 'white',
          snackbarColor: 'warning'
        })
        return
      }
      try {
        const payload = {
          documents: this.activeSuggestions
        }
        await this.addCaseRequiredDocuments({ _id: this.currentCaseForm._id, payload })
        this.$emit('close-dialog')
        this.reset()
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async addCustomRequiredDocsToCaseModel (input, suggestion) {
      if (!this.activeSuggestions.length) {
        this.addToast({
          title: this.$t('warning|no_current_selection'),
          color: 'white',
          snackbarColor: 'warning'
        })
        return
      }
      try {
        const payload = {
          documents: this.activeSuggestions
        }
        await this.addCaseRequiredDocuments({ _id: this.currentCaseForm._id, payload })
        if (input === 'custom-suggestion') {
          const index = this.activeSuggestions.findIndex(sugg => sugg.id === suggestion.id)
          if (index !== -1) {
            this.activeSuggestions.splice(index, 1)
          }
        }
      } catch (e) {
        console.error(e, 'error')
      }
    },
    returnToList () {
      this.isCustomField = false
    },
    openCustomSuggestion () {
      this.isCustomField = true
    },
    selectCustomSuggestion () {
      this.$v.newField.$touch()
      if (this.$v.newField.$error || this.customFieldExists) return
      const suggestion = {
        id: uuidv4(),
        name: this.newField.name,
        fieldType: 'file-input',
        key: this.newFieldKey,
        customHashtag: true
      }
      this.activeSuggestions.push(suggestion)
      this.addCustomRequiredDocsToCaseModel('custom-suggestion', suggestion)
      this.query = null
      this.selectedField = null
      this.resetCustomFields()
      this.isCustomField = false
    },
    resetCustomFields () {
      this.newField = {
        name: null
      }
    },
    reset () {
      this.query = null
      this.selectedField = null
      this.activeItems = []
      this.activeSuggestions = []
    }
  }
}
</script>

<style lang="scss" scoped>
.virtual-scroll-list {
  max-height: 500px;
  overflow: auto;
}
.active {
  background: #EEEEEE;
}
</style>
